import React, { Fragment, useEffect, useState } from "react"

import classNames from "classnames"
import { PaginatorLink } from "headless-pagination"
import { useTranslation } from "react-i18next"

import ArrowNextSVG from "../assets/images/icons/Arrow_next.svg"
import ArrowPreviousSVG from "../assets/images/icons/Arrow_previous.svg"

import "./Pagination.sass"

type Props = {
  from: number
  to: number
  hasPrevious: boolean
  hasNext: boolean
  links: PaginatorLink[]
  setPage: (p: number) => void
  onPrevious: () => void
  onNext: () => void
  total: number
  items?: string
  className?: string
  entriesPerPage?: number
  setEntriesPerPage?: (n: number) => void
}

export function Pagination({
  from,
  to,
  hasPrevious,
  hasNext,
  onPrevious,
  onNext,
  links,
  setPage,
  total,
  items,
  className,
  entriesPerPage,
  setEntriesPerPage,
}: Props) {
  const { t } = useTranslation()

  const cn = classNames("Pagination", className)

  const showNEntriesPerPage = [10, 25, 50]
  const [internalEntriesPerPage, setInternalEntriesPerPage] =
    useState(entriesPerPage)

  const handleEntriesPerPageChange = (n: number) => {
    if (setEntriesPerPage) {
      setEntriesPerPage(n)
      setPage(1)
    }
  }

  useEffect(() => {
    if (internalEntriesPerPage !== entriesPerPage) {
      setPage(1)
      setInternalEntriesPerPage(entriesPerPage)
    }
  }, [internalEntriesPerPage, entriesPerPage, setPage])

  return (
    <div className={cn}>
      <div className="summary">
        {t("desktop.manage.pagination_showing_items", {
          from: from < total ? from : total,
          to: to < total ? to : total,
          total,
          items: items ?? t("desktop.manage.pagination_items"),
        })}
      </div>

      {(hasPrevious || hasNext) && (
        <div className="links">
          <button disabled={!hasPrevious} onClick={onPrevious}>
            <ArrowPreviousSVG />
          </button>

          {links.map((link: PaginatorLink, i: number) => (
            <button
              key={i}
              disabled={link.disabled}
              className={link.active ? "current" : "link"}
              onClick={() =>
                typeof link.label !== "string" ? setPage(link.label) : undefined
              }
            >
              <span>{link.label}</span>
            </button>
          ))}

          <button disabled={!hasNext} onClick={onNext}>
            <ArrowNextSVG />
          </button>
        </div>
      )}

      <div className="show-per-page">
        {t("desktop.manage.pagination_show")}
        {showNEntriesPerPage.map((n) => (
          <Fragment key={n}>
            <button
              className={n === entriesPerPage ? "active" : ""}
              onClick={() => handleEntriesPerPageChange(n)}
            >
              {n}
            </button>
            <span>|</span>
          </Fragment>
        ))}
        {t("desktop.manage.pagination_per_page")}
      </div>
    </div>
  )
}

export default Pagination
