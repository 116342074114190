import React, { PropsWithChildren, useEffect, useState } from "react"

import dayjs from "dayjs"
import { useHistory } from "react-router-dom"

import { useRefetch } from "../../../hooks/mobile/useRefetch"
import { checkinUrlRegex } from "../../../screens/Mobile/QRScanner"
import { openInAppBrowser } from "../../../utils"
import { App as CapApp, URLOpenListenerEvent } from "@capacitor/app"

import {
  useFetchDeskReservationsQuery,
  useFetchMyDeskReservationsQuery,
} from "../../../redux/api/deskReservations"
import { RootState, useAppSelector } from "../../../redux/reducers"

export const DeepLinkContext = React.createContext({})

const DeepLinkProvider: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  let history = useHistory()

  const isFrontdesk = (path: string) => {
    return (
      path.startsWith("/visitor-login") ||
      (path.startsWith("/health-screening") && !path.includes("employee"))
    )
  }

  const isVisitorPath = (path: string) => {
    return path.startsWith("/visitors")
  }

  const [shouldHandleDeskBooking, setShouldHandleDeskBooking] = useState(false)
  const [deskId, setDeskId] = useState<string | null>(null)
  const date = dayjs()
  const startOfDay = date.startOf("day").toISOString()
  const endOfDay = date.endOf("day").toISOString()

  // Fetch all desk reservations for the day
  const {
    data: deskReservations,
    refetch: refetchDeskReservations,
    isSuccess: isDeskReservationsLoaded,
  } = useFetchDeskReservationsQuery({
    start: startOfDay,
    end: endOfDay,
  })

  // Fetch user's desk reservations for the day
  const {
    data: myDeskReservations,
    refetch: refetchMyDeskReservations,
    isSuccess: isMyDeskReservationsLoaded,
  } = useFetchMyDeskReservationsQuery({
    start: startOfDay,
    end: endOfDay,
  })

  // Get user email
  const userEmail = useAppSelector((state: RootState) => state.user.entry.email)

  // Refetch queries automatically when the app resumes activity
  useRefetch([refetchDeskReservations, refetchMyDeskReservations])

  const handleDeskBookingProcess = (deskId: string) => {
    const isSomeOtherDeskAlreadyReservedByUser =
      myDeskReservations?.results?.some(
        (reservation) => reservation.desk.id !== deskId,
      ) || false

    const isDeskAlreadyReservedBySomeoneElse =
      deskReservations?.results?.some(
        (reservation) =>
          reservation.desk.id === deskId &&
          reservation.user.email !== userEmail,
      ) || false

    const isDeskAlreadyReservedByUser =
      myDeskReservations?.results?.some(
        (reservation) => reservation.desk.id === deskId,
      ) || false

    const isDeskAlreadyCheckedInByUser =
      myDeskReservations?.results?.some(
        (reservation) =>
          reservation.desk.id === deskId && reservation.checked_in !== null,
      ) || false

    // Navigate to the correct screen based on the conditions
    if (isSomeOtherDeskAlreadyReservedByUser || isDeskAlreadyCheckedInByUser) {
      // If the user has already reserved or checked in another desk for the day, redirect to the wrong desk screen
      history.push("/book/desk/wrong")
    } else if (isDeskAlreadyReservedBySomeoneElse) {
      // If the desk is already reserved, redirect to the occupied screen
      history.push({
        pathname: "/book/desk/occupied",
        state: { desk_id: deskId },
      })
    } else if (isDeskAlreadyReservedByUser && !isDeskAlreadyCheckedInByUser) {
      // If the user has already reserved that desk but hasn't checked in yet, redirect to the check-in screen
      history.push({
        pathname: "/book/desk/checkin",
        state: { desk_id: deskId },
      })
    } else {
      // If none of the above, redirect to the confirm screen
      history.push({
        pathname: "/book/desk/confirm",
        state: { desk_id: deskId },
      })
    }
  }

  useEffect(() => {
    CapApp.addListener("appUrlOpen", async (data: URLOpenListenerEvent) => {
      const url = new URL(data.url)

      if (url.pathname) {
        if (isFrontdesk(url.pathname)) {
          // Frontdesk paths
          window.location.href =
            window.location.origin + url.pathname + url.search
        } else if (isVisitorPath(url.pathname)) {
          // Visitor paths
          await openInAppBrowser(data.url)
        } else if (url.pathname.match(checkinUrlRegex)) {
          // Desk booking paths
          const match = checkinUrlRegex.exec(url.pathname)
          if (match && match[1]) {
            setDeskId(match[1])
            setShouldHandleDeskBooking(true)
          }
        } else {
          // Other paths
          history.push(url.pathname)
        }
      }
    })
  }, [history])

  // Handle the desk booking process once all data is loaded
  useEffect(() => {
    if (
      shouldHandleDeskBooking &&
      isDeskReservationsLoaded &&
      isMyDeskReservationsLoaded &&
      deskId
    ) {
      handleDeskBookingProcess(deskId)
      setShouldHandleDeskBooking(false)
    }
  }, [
    shouldHandleDeskBooking,
    isDeskReservationsLoaded,
    isMyDeskReservationsLoaded,
    deskId,
  ])

  return (
    <DeepLinkContext.Provider value={{}}>{children}</DeepLinkContext.Provider>
  )
}

export default DeepLinkProvider
