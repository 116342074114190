import { BILLING_URL } from "../api"
import { COUNTRIES, FEATURE_FLAGS } from "../constants"
import {
  BILLING_PATHS,
  getPlanGroupPath,
} from "../screens/Settings/Billing/constants"
import { openBrowserWindow } from "../utils"
import { useCheckForFeatureFlag } from "./useCheckForFeatureFlag"
import { useNavigation } from "./useNavigation"

import {
  CURRENCIES,
  PAYMENT_METHODS,
  RECOMMENDED_PLANS,
  SUBSCRIPTION_PERIODS,
} from "../redux/api/billing/constants"
import { useFetchPaymentsQuery } from "../redux/api/billing/payments"
import { useFetchSubscriptionsQuery } from "../redux/api/billing/subscriptions"
import { PlanGroup } from "../redux/api/billing/types"
import { useFetchCompanyQuery } from "../redux/api/company"

/**
 * Custom hook that provides a function to navigate to the plan creation page.
 * The function takes a plan group as a parameter and handles the navigation based on certain conditions.

 *
 * @returns A function that can be used to navigate to the plan creation page.
 */
export const useNavigateToPlanCreation = () => {
  const isPortalMergerEnabled = useCheckForFeatureFlag(
    FEATURE_FLAGS.PORTAL_MERGER,
  )
  const { push, getNextFromCurrentUrl } = useNavigation()

  const { data: { results: subscriptions = [], count } = {} } =
    useFetchSubscriptionsQuery()
  const { data: payments } = useFetchPaymentsQuery()
  const { data: company } = useFetchCompanyQuery()

  /**
   * Retrieves the currency based on certain conditions.
   *
   * @returns The currency as a string.
   */
  const getCurrency = () => {
    if (count) {
      return subscriptions[0].plan_variation.currency
    }

    if (payments?.billing_details) {
      return payments?.billing_details.country === COUNTRIES.US
        ? CURRENCIES.USD
        : CURRENCIES.EUR
    }

    return company?.country === COUNTRIES.US ? CURRENCIES.USD : CURRENCIES.EUR
  }

  /**
   * Navigates to the plan creation page based on the provided plan group.
   *
   * @param planGroup - The plan group to navigate to.
   */
  const toPlanCreation = (planGroup: PlanGroup) => {
    if (!isPortalMergerEnabled) {
      openBrowserWindow(BILLING_URL, "portal_plans_and_billing")

      return
    }
    if (payments?.type === PAYMENT_METHODS.PREPAID_CODE) {
      push(BILLING_PATHS.overview.root)

      return
    }

    push(getPlanGroupPath(planGroup).root, {
      period: SUBSCRIPTION_PERIODS.YEAR,
      currency: getCurrency(),
      planType: RECOMMENDED_PLANS[planGroup],
      next: getNextFromCurrentUrl(),
    })
  }

  return toPlanCreation
}
