import { FC, useEffect, useRef, useState } from "react"

import classNames from "classnames"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import { userTimeFormat } from "../../../utils"
import { useFloorPlanContext } from "./FloorPlanProvider"
import { DeskWithReservations } from "./types"

import { AmenityResponse } from "../../../redux/amenities/types"
import { DepartmentResponse } from "../../../redux/api/departments/types"
import { DeskScheduleReservation } from "../../../redux/desk_schedule/types"
import { formatUser } from "../../../redux/user/utils"

import Button from "../../../components/advanced/Button"

import ChevronSVG from "../../../assets/images/icons/Chevron.svg"

import "./DeskList.sass"

type DeskListProps = {
  desksWithReservations: DeskWithReservations[]
  canBookDesks: boolean
}

/**
 * Component: DeskList
 */
const DeskList = ({ desksWithReservations, canBookDesks }: DeskListProps) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) {
      document.body.style.setProperty(
        "--desk-list-height",
        `${ref.current.clientHeight - 60}px`,
      )
    }
  }, [])

  useEffect(() => {
    ref.current?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }, [desksWithReservations.length])

  return (
    <div className="desk-list-wrapper">
      <div className="floor-plan-desk-list" ref={ref}>
        {desksWithReservations.map((d) => (
          <DeskItem canBookDesks={canBookDesks} key={d.id} desk={d} />
        ))}
      </div>
    </div>
  )
}

/**
 * Component: DeskItem
 */
type DeskItemProps = {
  desk: DeskWithReservations
  canBookDesks: boolean
}
export const DeskItem: FC<DeskItemProps> = ({ desk, canBookDesks }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [isSubdued, setIsSubdued] = useState<boolean>(false)

  const {
    hoveredDesk,
    clearHoveredDesk,
    openedDesk,
    setOpenedDesk,
    clearOpenedDesk,
    createNewReservation,
    setHoveredDesk,
    scrollToView,
  } = useFloorPlanContext()
  const { t } = useTranslation()

  const isOpened = desk.id === openedDesk
  const isHovered = desk.id === hoveredDesk
  const hasReservations = desk.reservations.length > 0
  const hasDepartments = (desk.departments?.length ?? 0) > 0
  const hasAmenities = desk.amenities.length > 0
  const deskStatus = desk.isDisabled
    ? "disabled"
    : hasReservations
      ? "booked"
      : "available"

  const classes = classNames("desk-item", {
    hovered: isHovered,
    isOpened,
    disabled: desk.isDisabled,
  })
  const reservationListClasses = classNames({ subdued: isSubdued })
  const detailsClasses = classNames("details", isOpened ? "opened" : "closed")

  const handleOnHeaderClick = (id: string) => {
    isOpened ? clearOpenedDesk() : setOpenedDesk(id)
  }

  const handleBookClick = (desk: DeskWithReservations) => {
    createNewReservation(desk)
  }

  useEffect(() => {
    if (isOpened && scrollToView) {
      ref.current?.scrollIntoView({
        block: "start",
        behavior: "smooth",
      })
    }
  }, [isOpened, scrollToView])

  return (
    <div
      className={classes}
      ref={ref}
      onMouseEnter={() => setHoveredDesk(desk.id)}
      onMouseLeave={() => clearHoveredDesk()}
    >
      <div
        className="header"
        onClick={() => {
          handleOnHeaderClick(desk.id)
        }}
      >
        <div className="title">
          <div className="desk-name">{desk.name}</div>
          <div className="icon">
            <ChevronSVG />
          </div>
        </div>
        <div className="status">
          <StatusDot status={deskStatus} />{" "}
          <span>{t("desktop.manage.floor_plan.desk_item_desk")}</span>
        </div>
      </div>

      <div className={detailsClasses}>
        {hasDepartments && (
          <DepartmentList departments={desk.departments ?? []} />
        )}
        {hasAmenities && <AmenitiesList amenities={desk.amenities ?? []} />}
        {hasReservations && (
          <ReservationList
            reservations={desk.reservations}
            className={reservationListClasses}
          />
        )}
        {!desk.isDisabled && canBookDesks && (
          <Button
            className="book-desk"
            variant="link"
            onClick={() => handleBookClick(desk)}
            onMouseEnter={() => setIsSubdued(true)}
            onMouseLeave={() => setIsSubdued(false)}
          >
            {t("desktop.manage.floor_plan.desk_item_book_desk")}
          </Button>
        )}
      </div>
    </div>
  )
}

/**
 * Component: StatusDot
 */
type StatusDotProps = { status: "booked" | "available" | "disabled" }

export const StatusDot = ({ status }: StatusDotProps) => {
  const classes = classNames("status-dot", status)

  return <div className={classes} />
}

/**
 * Component: DepartmentList
 */
type DepartmentListProps = {
  departments: DepartmentResponse[]
}

export const DepartmentList = ({ departments }: DepartmentListProps) => {
  return (
    <div className="department-list">
      {departments.map((d) => (
        <div className="department-item" key={d.id}>
          {d.name}
        </div>
      ))}
    </div>
  )
}

/**
 * Component: AmenitiesList
 */
type AmenitiesListProps = {
  amenities: AmenityResponse[]
}

export const AmenitiesList = ({ amenities }: AmenitiesListProps) => {
  const amenityNames = amenities.map((a) => a.name)

  return <div className="amenities-list">{amenityNames.join(", ")}</div>
}

/**
 * Component: ReservationList
 */
type ReservationListProps = {
  reservations: DeskScheduleReservation[]
  className?: string
}

export const ReservationList = ({
  reservations,
  className,
}: ReservationListProps) => {
  const classes = classNames("reservation-list", className)
  return (
    <div className={classes}>
      {reservations.map((r) => (
        <ReservationItem reservation={r} key={r.id} />
      ))}
    </div>
  )
}

/**
 * Component: ReservationItem
 */
type ReservationItemProps = {
  reservation: DeskScheduleReservation
}

export const ReservationItem = ({ reservation }: ReservationItemProps) => {
  const { user, start, end } = reservation
  const { openReservation } = useFloorPlanContext()

  const startTime = dayjs(start).format(userTimeFormat())
  const endTime = dayjs(end).format(userTimeFormat())

  return (
    <button
      className="reservation-item"
      type="button"
      onClick={() => openReservation(reservation.id)}
    >
      <div className="reservation-name">{formatUser(user)}</div>
      <div className="reservation-time">{`${startTime} - ${endTime}`}</div>
    </button>
  )
}

export default DeskList
