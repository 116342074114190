import { PropsWithChildren, ReactElement, ReactNode, ReactPortal } from "react"

import { Dayjs } from "dayjs"

export enum RecurringType {
  EVERY_DAY = "every_day",
  EVERY_WEEKDAY = "every_weekday",
  EVERY_DAY_OF_WEEK = "every_dayofweek",
}

export const isRecurringType = (value: string): value is RecurringType =>
  Object.values<string>(RecurringType).includes(value)

/**
 * @example
 * const TestObjectConst = {
 * 	A: 'a',
 * 	B:'b',
 * } as const
 * type TestType = ObjectToUnion<typeof TestObjectConst>
 *  // TestType = 'a' | 'b'
 *
 */
export type ObjectToUnion<TObject> = TObject[keyof TObject]
export type ArrayToUnion<T extends readonly any[]> = T[number]

export type InternalTime = `${number}${number}:${number}${number}`

export type AddAllAndNumber<UnionType extends string> = {
  [P in UnionType]: P | `${P}:all` | `${P}:${number}`
}[UnionType]

export type OptionType<Value = string> = {
  value: Value
  label: string | ReactNode
  disabled?: boolean
  tooltip?: string | ReactNode
  className?: string
}

export type OptionalKeysRecord<
  UnionType extends string,
  ValueType extends unknown,
> = {
  [K in UnionType]?: ValueType
}

export type MultiOptionType<TValue extends string | number> = {
  value: TValue
  label: string | ReactNode
  isSingleSelect?: boolean
}

export type IdAndName = {
  id: string
  name: string
}

export type KeyAndName = {
  key: string
  name: string
}

export interface GroupBase<Option> {
  options: Option[]
  label?: string
}

export type FCC<Props = unknown> = React.FC<PropsWithChildren<Props>>

export type Children =
  | ReactElement
  | string
  | number
  | Iterable<Children>
  | ReactPortal
  | boolean
  | null
  | undefined

export type ISODate = string

export type Facet = {
  id: string
  name: string
  count: number
}

export type ImageResizeOptions = {
  width?: number
  height?: number
  mode: number
}

/**
 * Represents a path in a nested object for elements with type string.
 * @template T - The type of the nested object.
 * @template Key - The key type of the nested object.
 */

export type PathToStrings<
  T,
  Key extends keyof any = keyof T,
> = Key extends string
  ? Key extends keyof T
    ? T[Key] extends string
      ? Key
      : T[Key] extends Record<string, any>
        ? `${Key}.${PathToStrings<T[Key], keyof T[Key]>}`
        : never
    : never
  : never

export type RangeStartEnd = {
  start: Dayjs
  end: Dayjs
}

export type RangeFromTo = {
  from: Dayjs
  to: Dayjs
}

export type SettingsRoom = {
  meet_now?: number
  meet_later?: boolean
  cancel_meeting?: number
  check_into_meeting: number
  show_meeting_subject?: boolean
  show_meeting_description?: boolean
  show_meeting_organizer?: boolean
  show_tentative_meetings?: boolean
  touch?: boolean
  sound?: boolean
  charging_screen?: boolean
  disconnect_screen?: boolean
  cleaning_status?: boolean
  logo?: {
    name: string
    path: string
    size: string
  }
  ampm: boolean
  timezone: string
  language: string
}

export enum RepeatPickerEnum {
  ONCE = "once",
}

export type RepeatPickerTypes = RepeatPickerEnum | RecurringType

export type ScheduleReservation = {
  id: string
  user: {
    email: string
    first_name?: string
    last_name?: string
  }
  start?: string
  end?: string
  checked_in: CheckedIn | null
  visit_id?: string
  tz?: string
}

export type CheckedIn = {
  check_in_at?: string
  check_out_at?: string
}

export type UserProfile = {
  picture?: ImageResponse
  job_title?: string | null
  job_role?: string | null
  use_case?: string[]
  discovery?: string
  notify_room?: boolean | null
  notify_desk?: boolean | null
  notify_visitor?: boolean | null
  notify_system?: boolean | null
  notify_channel_email?: boolean | null
  notify_channel_slack?: boolean | null
  notify_channel_teams?: boolean | null
  notify_channel_sms?: boolean | null
}

export type ImageResponse = {
  id: string
  name: string
  path: string
  size?: number
  url?: string
}
