import React, { useMemo } from "react"

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { useCalculateBookable } from "../../../hooks/useCalculateBookable"
import DeskList from "./Desks/DeskList"
import NoDeskFound from "./Desks/NoDeskFound"

import { useFetchDeskReservationsQuery } from "../../../redux/api/deskReservations"
import { useFetchDeskQuery, useFetchDesksQuery } from "../../../redux/api/desks"

import Loader from "../../../components/basic/Loader"

import "./NearbyDesks.sass"

type NearbyDesksProps = {
  deskId: string
}

const NearbyDesks = ({ deskId }: NearbyDesksProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const date = dayjs()
  const startOfDay = date.startOf("day").toISOString()
  const endOfDay = date.endOf("day").toISOString()
  const timeslot = {
    from: startOfDay,
    to: endOfDay,
  }

  // Fetch the current desk data
  const { data: desk, isLoading: isDeskLoading } = useFetchDeskQuery(deskId)

  // Fetch desks on the same floor and building
  const { data: desks, isLoading: isDesksLoading } = useFetchDesksQuery(
    {
      floor: desk?.floor?.id,
      building: desk?.building?.id,
    },
    {
      skip: !desk || !desk.floor?.id || !desk.building?.id,
    },
  )

  // Fetch desk reservations on the same floor and building
  const {
    data: { results: reservations = [] } = {},
    isLoading: isReservationsLoading,
  } = useFetchDeskReservationsQuery(
    {
      start: startOfDay,
      end: endOfDay,
      building_id: desk?.building?.id,
      floor_id: desk?.floor?.id,
    },
    {
      skip: !desk || !desk.floor?.id || !desk.building?.id,
    },
  )

  // Fetch bookable desks
  const { desksBookable, isLoading: isBookableLoading } = useCalculateBookable({
    date: startOfDay,
    timeslot,
    floorId: desk?.floor?.id,
    buildingId: desk?.building?.id,
  })

  // Combine desks with reservations
  const desksWithReservations = useMemo(() => {
    return (
      desks?.results.map((desk) => {
        const deskReservations = reservations.filter(
          (res) => res.desk.id === desk.id,
        )
        return { ...desk, reservations: deskReservations }
      }) || []
    )
  }, [desks, reservations])

  // Filter bookable desks that are not reserved
  const bookableDesks = useMemo(() => {
    if (!desksWithReservations || !desksBookable) return []
    return desksWithReservations.filter(
      (desk) => desksBookable[desk.id] && desk.reservations.length === 0,
    )
  }, [desksWithReservations, desksBookable])

  // Map the bookable desks to the structure expected by DeskList and limit to 3
  const bookableNearbyDesks = bookableDesks.slice(0, 3).map((desk) => ({
    id: desk.id,
    name: desk.name,
    buildingName: desk.building.name,
    floorName: desk.floor.name,
  }))

  const isLoading =
    isDeskLoading ||
    isDesksLoading ||
    isBookableLoading ||
    isReservationsLoading

  const handleDeskClick = (desk: { id: string }) => {
    history.push(`/book/desk/confirm`, { desk_id: desk.id })
  }

  return (
    <div className="NearbyDesks">
      {isLoading && <Loader variant="fullScreen" />}

      {!isLoading && bookableNearbyDesks.length > 0 && (
        <DeskList
          desks={bookableNearbyDesks || []}
          onDeskClick={handleDeskClick}
        />
      )}

      {!isLoading && bookableNearbyDesks.length === 0 && (
        <NoDeskFound
          message={t("mobile.book.no_available_nearby_desks_found")}
        ></NoDeskFound>
      )}
    </div>
  )
}

export default NearbyDesks
