import React, { useEffect, useState } from "react"

import classNames from "classnames"
import dayjs, { Dayjs } from "dayjs"
import { ParseKeys } from "i18next"
import { useTranslation } from "react-i18next"

import { useFromToCalculator } from "../../hooks/useFromToCalculator"
import { RepeatPickerTypes } from "../../types/sharedTypes"
import Button from "../advanced/Button"
import { CategoryRow } from "./CategoryRow"
import { DatePicker } from "./DatePicker"
import { Drawer } from "./Drawer"
import { RepeatPicker, repeatPickerTypes } from "./RepeatPicker"
import { TimeslotPicker } from "./TimeslotPicker"
import { TopNav } from "./TopNav"
import ValueSelect from "./ValueSelect"
import isBetween from "dayjs/plugin/isBetween"

import { TimeslotResponse } from "../../redux/timeslots/types"

import "./DateTimePicker.sass"

dayjs.extend(isBetween)

type DateTimePickerProps = {
  date: Dayjs
  timeslot: Partial<TimeslotResponse> | null
  preferredTime: string
  defaultTimeSpanMinutes?: number
  onlyInput?: boolean
  unavailableSlots?: Array<Partial<TimeslotResponse>>
  onPick: (
    date: Dayjs | null,
    timeslot: Partial<TimeslotResponse> | null,
    repeat: RepeatPickerTypes | null,
    repeatUntil: Dayjs | null,
  ) => void
  onCancelClick?: () => void
  isRoomBooking?: boolean
  repeat?: RepeatPickerTypes
  repeatUntil?: Dayjs | null
  onAdhocBooking?: () => void
}

export const DateTimePicker: React.FC<DateTimePickerProps> = ({
  date,
  timeslot,
  preferredTime,
  defaultTimeSpanMinutes = 480,
  onlyInput = false,
  unavailableSlots,
  onPick,
  onCancelClick,
  isRoomBooking = false,
  repeat: storedRepeat,
  repeatUntil: storedRepeatUntil,
  onAdhocBooking,
}) => {
  const { t } = useTranslation()

  const { defaultFrom, defaultTo } = useFromToCalculator(
    undefined,
    isRoomBooking,
  )

  const [selectedDate, setSelectedDate] = useState(date)
  const [selectedSlot, setSelectedSlot] = useState(timeslot)
  const [customFrom, setCustomFrom] = useState(defaultFrom)
  const [customTo, setCustomTo] = useState(defaultTo)
  const [repeat, setRepeat] = useState<RepeatPickerTypes>(
    storedRepeat ?? repeatPickerTypes.ONCE,
  )
  const [repeatUntil, setRepeatUntil] = useState<Dayjs | null>(
    storedRepeatUntil ?? null,
  )
  const [repeatPicker, setRepeatPicker] = useState(false)

  const handleRepeatPick = (
    repeatOption: RepeatPickerTypes,
    untilDate: Dayjs | null,
  ) => {
    setRepeat(repeatOption)
    setRepeatUntil(untilDate)
    setRepeatPicker(false)
    if (onAdhocBooking) {
      onPick(selectedDate, selectedSlot, repeatOption, untilDate)
    }
  }

  useEffect(() => {
    setSelectedDate(date)
    setCustomFrom(timeslot?.from ?? defaultFrom)
    setCustomTo(timeslot?.to ?? defaultTo)
    setSelectedSlot({
      from: timeslot?.from ?? defaultFrom,
      to: timeslot?.to ?? defaultTo,
      id: timeslot?.id ?? "",
    })
  }, [date, timeslot])

  const handleDateChange = (newDate: Dayjs) => {
    setSelectedDate(newDate)
    if (onAdhocBooking) {
      onPick(newDate, selectedSlot, repeat, repeatUntil)
    }
  }

  const handleTimeslotChange = (newSlot: Partial<TimeslotResponse>) => {
    setSelectedSlot(newSlot)
    if (onAdhocBooking) {
      onPick(selectedDate, newSlot, repeat, repeatUntil)
    }
  }

  const handleSave = () => {
    if (selectedSlot !== null) {
      onPick(selectedDate, selectedSlot, repeat, repeatUntil)
      if (onAdhocBooking) {
        onAdhocBooking()
      }
    }
  }

  const DateTimePickerClasses = classNames("DateTimePicker", {
    "adhoc-booking": onAdhocBooking,
  })

  return (
    <div className={DateTimePickerClasses}>
      <div className="picker-content">
        {!onAdhocBooking && (
          <div className="section-head">
            <TopNav
              backArrow={false}
              title={t("mobile.general.choose_time")}
              titleCenter={true}
              onClose={() => {
                if (onCancelClick) {
                  onCancelClick()
                  return
                }
                onPick(null, null, null, null)
              }}
              onTopScreen={false}
            />
          </div>
        )}

        <div className="section">
          <CategoryRow name={t("mobile.general.date")}>
            <DatePicker
              selectedDate={selectedDate}
              onSelect={handleDateChange}
            />
          </CategoryRow>
        </div>

        <div className="section no-border">
          <CategoryRow name={t("mobile.general.timeslot")}>
            <TimeslotPicker
              selectedSlot={selectedSlot}
              onSelect={(newSlot: Partial<TimeslotResponse> | null) => {
                if (newSlot !== null) {
                  handleTimeslotChange(newSlot)
                }
              }}
              customFrom={customFrom}
              onCustomFrom={setCustomFrom}
              customTo={customTo}
              onCustomTo={setCustomTo}
              preferredTime={preferredTime}
              defaultTimeSpanMinutes={defaultTimeSpanMinutes}
              onlyInput={onlyInput}
              unavailableSlots={unavailableSlots}
            />
          </CategoryRow>
        </div>

        {!isRoomBooking && (
          <div className="section full-width">
            <CategoryRow name={t("mobile.book.repeat")}>
              <ValueSelect
                value={
                  <>
                    {t(`mobile.general.repeat.${repeat}` as ParseKeys)}
                    {repeat === repeatPickerTypes.EVERY_DAY_OF_WEEK &&
                      dayjs(date).format(" dddd")}
                    {repeatUntil &&
                      repeat !== repeatPickerTypes.ONCE &&
                      " (" +
                        t("mobile.general.until") +
                        ": " +
                        repeatUntil.format("MMM D") +
                        ")"}
                  </>
                }
                onClick={() => setRepeatPicker(true)}
              />
            </CategoryRow>
          </div>
        )}

        <div className="section-foot">
          <Button
            variant="mobile-action"
            isDisabled={
              customFrom === "" || customTo === "" || selectedSlot === null
            }
            onClick={handleSave}
          >
            {onAdhocBooking
              ? selectedDate.isToday()
                ? t("mobile.general.book_now")
                : t("mobile.general.book")
              : t("mobile.general.confirm")}
          </Button>
        </div>
      </div>

      <Drawer open={repeatPicker}>
        <RepeatPicker
          date={selectedDate}
          value={repeat}
          until={repeatUntil}
          onPick={handleRepeatPick}
        />
      </Drawer>
    </div>
  )
}
