import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"

import dayjs from "dayjs"
import { ParseKeys } from "i18next"
import { useTranslation } from "react-i18next"

import { ENTRIES_PER_PAGE_KEY, LabelPaths } from "../../../../constants"
import { useBackendPagination } from "../../../../hooks/useBackendPagination"
import { useLocalStorage } from "../../../../hooks/useLocalStorage"
import { useNavigation } from "../../../../hooks/useNavigation"
import { useStoredFilter } from "../../../../hooks/useStoredFilter"
import { useToast } from "../../../../hooks/useToast"
import { KeyAndName, OptionType } from "../../../../types/sharedTypes"
import { generateString, getLabel, updateHistory } from "../../../../utils"
import { ENTRIES_PER_PAGE } from "../../../Manage/constants"
import LicensesInfoList from "../../LicensesInfoList"
import {
  ROOM_DEVICE_FILTERS_STORE_NAME,
  ROOMS_DEVICES_PATHNAME,
  ROOMS_PATHS,
} from "../constants"
import OnboardingTableInfo from "../OnboardingTableInfo"
import DevicesFilters, { Filter, FilterKeys } from "./Filters"
import { useModals } from "@mattjennings/react-modal-stack"
import { isRejected } from "@reduxjs/toolkit"

import { SUBSCRIPTION_UNIT_TYPES } from "../../../../redux/api/billing/constants"
import {
  useFetchRoomsDevicesQuery,
  useRepairRoomDeviceMutation,
  useUnpairRoomDeviceMutation,
} from "../../../../redux/api/devices"
import { AVAILABLE_FACETS } from "../../../../redux/api/devices/constants"
import {
  DevicesFacets,
  RoomDeviceResponse,
} from "../../../../redux/api/devices/types"
import { isApiResponseError } from "../../../../redux/api/types"

import Button from "../../../../components/advanced/Button"
import { ConfirmationModal } from "../../../../components/advanced/ConfirmationModal"
import Table from "../../../../components/advanced/Table"
import MultiActionButton from "../../../../components/basic/MultiActionButton"
import { Column } from "../../../../components/basic/Table"
import { Tooltip } from "../../../../components/basic/Tooltip"
import Breadcrumbs from "../../../../components/Breadcrumbs"
import FilterSpace from "../../../../components/Filter/FilterSpace"
import { FilterSpecialValues } from "../../../../components/Filter/types"
import Intro from "../../../../components/Intro"
import NoDataFound from "../../../../components/NoDataFound"
import Pagination from "../../../../components/Pagination"
import Space from "../../../../components/Space"
import View from "../../../../components/View"
import RoomDeviceStatus from "../../../../components/Visitors/RoomDeviceStatus"

import DeviceSVG from "../../../../assets/images/icons/Device.svg"

import "./styles.sass"

const NoDevicesFound = () => {
  const { t } = useTranslation()

  return (
    <NoDataFound>
      <>
        <DeviceSVG />
        <p>{t("desktop.settings.rooms.devices.no_devices.title")}</p>
      </>
    </NoDataFound>
  )
}

enum RoomDeviceBulkAction {
  RE_PAIR = "RE_PAIR",
  UN_PAIR = "UN_PAIR",
}

const queryUseFacets = AVAILABLE_FACETS.map(
  (facet) => `${facet}:all` as DevicesFacets,
)

const mapFacetToFilters: Record<DevicesFacets, Partial<FilterKeys>> = {
  building: "building_id",
  floor: "floor_id",
  type: "type",
}

const Devices = () => {
  const {
    push,
    location: { pathname, hash },
  } = useNavigation()

  const { t } = useTranslation()
  const { openModal, closeModal } = useModals()
  const { errorToast, infoToast } = useToast()

  const [repairRoomDevice] = useRepairRoomDeviceMutation()
  const [unpairRoomDevice] = useUnpairRoomDeviceMutation()

  const defaultFilter: Filter = {
    building_id: FilterSpecialValues.ALL,
    floor_id: FilterSpecialValues.ALL,
    type: FilterSpecialValues.ALL,
    battery: FilterSpecialValues.ALL,
    connectivity: FilterSpecialValues.ALL,
    search: "",
  }

  const [storedFilterValues, saveFilter] = useStoredFilter({
    filterName: ROOM_DEVICE_FILTERS_STORE_NAME,
    defaultFilterValues: defaultFilter,
  })

  const [filters, setFilters] = useState<Filter>(storedFilterValues)

  const [page, setPage] = useState(1)

  const reqParams = useRef<Filter>(storedFilterValues)

  const handleFilterChange = useCallback(
    async (filter: Filter) => {
      reqParams.current = filter
      updateHistory(pathname, reqParams.current, hash)
      setFilters(filter)
      setPage(1)
    },
    [pathname, hash],
  )

  const [selectedRows, setSelectedRows] = useState<RoomDeviceResponse[]>([])

  const selectedIdsDevices = useMemo(
    () => selectedRows.map((sd) => sd.id),
    [selectedRows],
  )

  const { value: entriesPerPage, onChange: setEntriesPerPage } =
    useLocalStorage(ENTRIES_PER_PAGE_KEY, ENTRIES_PER_PAGE.toString())

  const entriesPerPageNum = parseInt(entriesPerPage)

  const {
    data: {
      results: roomsDevices = [],
      facets = {},
      offset = 0,
      count = 0,
    } = {},
    isFetching: isLoading,
  } = useFetchRoomsDevicesQuery({
    building: filters.building_id,
    floor: filters.floor_id,
    type: filters.type,
    battery: filters.battery,
    connectivity: filters.connectivity,
    search: filters.search,
    facets: queryUseFacets,
    offset: (page - 1) * entriesPerPageNum,
    limit: entriesPerPageNum,
  })

  const { from, to, hasNext, hasPrevious, paginationLinks } =
    useBackendPagination({
      offset,
      totalNumberOfItems: count,
      entriesPerPage: entriesPerPageNum,
      maxLinks: 7,
      maxTrailingLinks: 2,
    })

  const handleSelectRow = (d: RoomDeviceResponse[]) => setSelectedRows(d)

  const bulkActionOptions = useMemo<OptionType<RoomDeviceBulkAction>[]>(
    () =>
      Object.values(RoomDeviceBulkAction).map((action) => ({
        label: t(
          `desktop.settings.rooms.devices.actions.${action.toLocaleLowerCase()}` as ParseKeys,
        ),
        value: action,
      })),
    [t],
  )

  const tableColumns = useMemo<Column<RoomDeviceResponse>[]>(
    () => [
      {
        field: "name",
        cellClassName: "device",
        label: t("desktop.settings.rooms.devices.table.device_name"),
        renderCell: ({ name, type }) => {
          const deviceType = getLabel(
            `deviceTypes.${type.toLowerCase()}` as LabelPaths,
          )

          return deviceType + (name && ` - ${name}`)
        },
      },
      {
        field: "rooms",
        cellClassName: "rooms",
        label: t("desktop.settings.rooms.devices.table.calendar"),
        renderCell: ({ rooms }) => roomsDevicesCell(rooms),
      },
      {
        field: "type",
        label: t("desktop.settings.rooms.devices.table.device_status"),
        renderCell: (device) => <RoomDeviceStatus device={device} />,
      },
      {
        field: "status_sent_at",
        cellClassName: "last-reported",
        label: t("desktop.settings.rooms.devices.table.last_reported"),
        renderCell: ({ status_sent_at }) =>
          status_sent_at ? dayjs(status_sent_at).fromNow() : "-",
      },
    ],
    [t],
  )

  const roomsDevicesCell = (rooms: KeyAndName[]) => {
    if (rooms.length === 0) return "-"

    const uniqId = generateString(4)

    if (rooms.length === 1) {
      return (
        <Tooltip
          className="single-room"
          key={uniqId}
          uniqueId={uniqId}
          content={rooms[0].name}
        >
          {rooms[0].name}
        </Tooltip>
      )
    }

    return (
      <>
        {rooms.map((room, index, array) => {
          const isLastElement = index === array.length - 1

          return (
            <span key={room?.name ?? index}>
              {room?.name ?? ""}
              {!isLastElement ? ", " : ""}
            </span>
          )
        })}
      </>
    )
  }

  const handleRepair = async () => {
    if (selectedIdsDevices.length) {
      const response = await repairRoomDevice(selectedIdsDevices)

      if (isRejected(response)) {
        const { error } = response

        if (isApiResponseError(error)) {
          errorToast(error.message)
        }
        return
      }

      setSelectedRows([])

      infoToast(
        t("desktop.settings.rooms.devices.form.toasts.device_repaired_other", {
          count: selectedIdsDevices.length,
        }),
      )
    }
  }

  const handleUnpair = async () => {
    const response = await unpairRoomDevice(selectedIdsDevices)

    if (isRejected(response)) {
      const { error } = response

      if (isApiResponseError(error)) {
        errorToast(error.message)
      }
      return
    }

    setSelectedRows([])

    infoToast(
      t("desktop.settings.rooms.devices.form.toasts.device_unpaired_other", {
        count: selectedIdsDevices.length,
      }),
    )
  }

  const handleBulkAction = async (action: RoomDeviceBulkAction) => {
    switch (action) {
      case RoomDeviceBulkAction.RE_PAIR:
        handleRepair()
        break
      case RoomDeviceBulkAction.UN_PAIR:
        handleUnpair()
        break
    }
  }

  const handleBulkActionConfirmation = (action: RoomDeviceBulkAction) => {
    if (!selectedIdsDevices.length) {
      infoToast(
        t("desktop.settings.rooms.devices.form.toasts.no_devices_selected"),
      )
      return
    }

    openModal(ConfirmationModal, {
      onConfirm: async () => {
        await handleBulkAction(action)
        closeModal()
      },
    })
  }

  const handleRowClick = (d: RoomDeviceResponse) =>
    push(`${ROOMS_PATHS.devices}/${d.id}`)

  useEffect(
    () => () => {
      if (pathname.endsWith(ROOMS_DEVICES_PATHNAME)) {
        saveFilter(reqParams.current)
      }
    },
    [saveFilter, pathname],
  )

  return (
    <View className="SettingsRoomsDevices">
      <Breadcrumbs
        depth={2}
        includeParamsAsPath
        values={[
          t("desktop.settings.rooms.title"),
          t("desktop.settings.rooms.devices.title"),
        ]}
      />

      <Intro>
        <div className="description">
          {t("desktop.settings.rooms.devices.intro")}{" "}
          <a
            href={getLabel("links.appConfiguratorURL")}
            target="_blank"
            rel="noreferrer"
          >
            {t("desktop.settings.rooms.devices.download_here")}
          </a>
        </div>
        <div className="buttons">
          <Button to={ROOMS_PATHS.pair} isSmall>
            {t("desktop.settings.rooms.devices.pair_new_device")}
          </Button>
        </div>
      </Intro>

      <Space size={0.75} />

      <LicensesInfoList licenseType={SUBSCRIPTION_UNIT_TYPES.DEVICE} />

      <Space size={0.75} />

      <div className="Actions">
        <DevicesFilters
          onChange={handleFilterChange}
          defaultValues={storedFilterValues}
          facets={facets}
          mapFacetToFilters={mapFacetToFilters}
          useFacets={AVAILABLE_FACETS}
        />

        <FilterSpace />

        <MultiActionButton
          isSmall
          options={bulkActionOptions}
          onAction={handleBulkActionConfirmation}
          label={`${t("desktop.settings.rooms.devices.selected_devices")} (${
            selectedIdsDevices.length
          })`}
        />
      </div>

      <Space size={0.75} />

      <Table
        isSelectable
        loading={isLoading}
        rows={roomsDevices}
        columns={tableColumns}
        onSelectedRows={handleSelectRow}
        emptyTableCell={<NoDevicesFound />}
        onRowClick={handleRowClick}
        footerInfo={<OnboardingTableInfo />}
        pagination={
          <Pagination
            links={paginationLinks}
            setPage={setPage}
            onPrevious={() => setPage(page - 1)}
            onNext={() => setPage(page + 1)}
            hasNext={hasNext}
            hasPrevious={hasPrevious}
            from={from}
            to={to}
            total={count}
            items={t("desktop.settings.rooms.devices.items")}
            entriesPerPage={entriesPerPageNum}
            setEntriesPerPage={setEntriesPerPage}
          />
        }
      />
    </View>
  )
}

export default Devices
