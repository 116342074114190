import { Trans, useTranslation } from "react-i18next"

import { FETCH_FOR_COUNT } from "../../../../constants"
import { useNavigation } from "../../../../hooks/useNavigation"
import { useToast } from "../../../../hooks/useToast"
import { ROOMS_PATHNAME } from "../../Rooms/constants"
import { INTEGRATIONS_PATHS } from "../constants"
import { CALENDAR_PROVIDERS } from "./constants"

import {
  isExchangeCalendar,
  isGoogleCalendar,
  isICalendar,
  useUnpairCalendarMutation,
} from "../../../../redux/api/calendars"
import {
  AvailableCalendars,
  CalendarType,
} from "../../../../redux/api/calendars/types"
import { useFetchRoomsQuery } from "../../../../redux/api/rooms"
import { isRejected } from "../../../../redux/api/types"

import Button from "../../../../components/advanced/Button"
import Card from "../../../../components/basic/Card"
import NoDataFound from "../../../../components/NoDataFound"

import "./CalendarIntegratedCard.sass"

type CalendarIntegratedCardProps = {
  calendar: AvailableCalendars
}

const NoRoomsFound = () => {
  const { push } = useNavigation()
  return (
    <NoDataFound warning className="NoRoomsFound">
      <div>
        <Trans
          i18nKey={"desktop.settings.integrations.calendars.providers.no_rooms"}
        >
          <Button onClick={() => push(ROOMS_PATHNAME)} variant="link"></Button>
        </Trans>
      </div>
    </NoDataFound>
  )
}

const ViewRooms = () => {
  const { push } = useNavigation()
  return (
    <NoDataFound warning className="ViewRooms">
      <div>
        <Trans
          i18nKey={
            "desktop.settings.integrations.calendars.providers.view_rooms"
          }
        >
          <Button onClick={() => push(ROOMS_PATHNAME)} variant="link"></Button>
        </Trans>
      </div>
    </NoDataFound>
  )
}

const CalendarIntegratedCard = ({ calendar }: CalendarIntegratedCardProps) => {
  const { t } = useTranslation()
  const { push } = useNavigation()

  const { data: { count: roomCount = 0 } = {}, isLoading: areRoomsLoading } =
    useFetchRoomsQuery(FETCH_FOR_COUNT)

  const { errorToast } = useToast()

  const [unpairCalendar] = useUnpairCalendarMutation()

  const handleUnpairClick = async (calendar: AvailableCalendars) => {
    const response = await unpairCalendar({ id: calendar.id })

    if (isRejected(response)) {
      errorToast(response.error.message)
      return
    }
  }

  const handleEditICalcClick = () => push(INTEGRATIONS_PATHS.icalendar)

  const getCalendarIcon = (type: CalendarType) => CALENDAR_PROVIDERS[type].icon

  const getAdditionalDetails = () => {
    if (isGoogleCalendar(calendar)) {
      return (
        <>
          <div className="details__title additional">
            {t("desktop.settings.integrations.calendars.for_domain_name")}
          </div>
          <div>{calendar.domain}</div>
        </>
      )
    }
    if (isExchangeCalendar(calendar)) {
      return (
        <>
          <div className="details__title additional">
            {t("desktop.settings.integrations.calendars.exchange_server_url")}
          </div>
          <div>{calendar.url}</div>
        </>
      )
    }
  }

  return (
    <>
      <Card className="CalendarIntegratedCard">
        {getCalendarIcon(calendar.type)}
        <div className="details">
          {calendar.user && (
            <>
              <div className="details__title">
                {t("desktop.settings.integrations.calendars.logged_in_with")}
              </div>
              <div>{calendar.user}</div>
            </>
          )}

          {getAdditionalDetails()}
        </div>
        <div className="actions">
          {isICalendar(calendar) && (
            <Button
              onClick={handleEditICalcClick}
              variant="secondary"
              className="SyncCalendar"
            >
              {t("desktop.settings.integrations.calendars.edit_button")}
            </Button>
          )}
          <Button
            noConfirm
            onClick={() => handleUnpairClick(calendar)}
            variant="danger-pop"
            className="UnpairCalendar"
          >
            {t("desktop.settings.integrations.calendars.unpair_button")}
          </Button>
        </div>
      </Card>
      {!areRoomsLoading && roomCount === 0 && <NoRoomsFound />}
      {!areRoomsLoading && roomCount > 0 && <ViewRooms />}
    </>
  )
}

export default CalendarIntegratedCard
