import React from "react"

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { useBookContext } from "../../../../contexts/Mobile/BookContext"
import { useAnalyticsScreenView } from "../../../../providers/Mobile/FirebaseAnalyticsProvider"
import DeskList from "./DeskList"
import NoDeskFound from "./NoDeskFound"

import { useFetchMyDeskReservationsQuery } from "../../../../redux/api/deskReservations"

import Loader from "../../../../components/basic/Loader"
import { CorrectIconMapper } from "../../../../components/CorrectIconMapper"
import SafeViewArea from "../../../../components/Mobile/SafeViewArea"
import { TopNav } from "../../../../components/Mobile/TopNav"

import "./WrongDesk.sass"

const WrongDesk = () => {
  useAnalyticsScreenView("Book/Desks/WrongDesk")

  const { t } = useTranslation()
  const { goToHome } = useBookContext()
  const history = useHistory()
  const date = dayjs()
  const startOfDay = date.startOf("day").toISOString()
  const endOfDay = date.endOf("day").toISOString()

  // Fetch user's desk reservations
  const {
    data: myDeskReservations,
    error: myDeskReservationsError,
    isLoading: isMyDeskReservationsLoading,
  } = useFetchMyDeskReservationsQuery({
    start: startOfDay,
    end: endOfDay,
  })

  const handleClose = () => goToHome()

  const handleReservationClick = (desk: { id: string }) => {
    // Find the reservation based on the desk id and navigate to the reservation details screen
    const reservation = myDeskReservations?.results.find(
      (reservation) => reservation.id === desk.id,
    )

    if (reservation) {
      history.push(`/home/reservation/${reservation.id}`)
    }
  }

  return (
    <SafeViewArea className="WrongDesk">
      <div className="head">
        <TopNav onClose={handleClose} />
        <div className="icon">
          <div>
            <CorrectIconMapper iconType="desk" needsWrap={false} />
          </div>
        </div>
      </div>

      <div className="body">
        <div className="main-data">
          {isMyDeskReservationsLoading && <Loader variant="fullScreen" />}

          {myDeskReservations && !isMyDeskReservationsLoading && (
            <>
              <div className="title">{t("mobile.general.wrong_desk")}</div>
              <div className="subtitle">
                {t("mobile.general.check_your_booked_desk")}
              </div>

              <DeskList
                desks={myDeskReservations.results.map((reservation) => ({
                  id: reservation.id,
                  name: reservation.desk.name,
                  buildingName: reservation.building.name,
                  floorName: reservation.floor.name,
                }))}
                onDeskClick={handleReservationClick}
              />
            </>
          )}

          {myDeskReservationsError && !isMyDeskReservationsLoading && (
            <div className="error-message">
              <NoDeskFound />
            </div>
          )}
        </div>
      </div>
    </SafeViewArea>
  )
}

export default WrongDesk
