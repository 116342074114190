import dayjs from "dayjs"
import { t } from "i18next"

import { ObjectToUnion, OptionType } from "../../../../types/sharedTypes"
import { internalTimeFormat } from "../../../../utils"

export const DEVICE_SETTINGS_FEATURES = {
  GLOBAL: "Global settings",
  PER_BUILDING: "Per Locations/Building",
  PER_DEVICE: "Per device",
  SET_CUSTOM_LOGO: "Set Custom logo",
  CUSTOM_CONTENT: "Custom content",
  CREATE_CUSTOM_BUTTONS: "Create Custom Buttons",
  SET_LANGUAGE: "Set your preferred language",
  OPERATING_HOURS: "Operating hours",
  CANCEL_NO_SHOW_MEETINGS: "Cancel no-show meetings",
  CHECK_INTO_MEETINGS: "Check into meetings",
}

export const DEVICE_MEET_NOW_DURATION_OPTIONS: OptionType<number>[] = [
  0, 15, 30, 45, 60,
].map((v) => ({
  value: v,
  label:
    v === 0
      ? t(
          "desktop.settings.rooms.device_settings.form.device_settings.time_duration_options.off",
        )
      : t(
          "desktop.settings.rooms.device_settings.form.device_settings.time_duration_options.number_of_minutes",
          { number: v },
        ),
}))

export const DEVICE_CANCELATION_EVENT_OPTIONS: OptionType<number>[] = [
  {
    value: 0,
    label: t(
      "desktop.settings.rooms.device_settings.form.custom_button.device_cancelation_events.off",
    ),
  },
  {
    value: 1,
    label: t(
      "desktop.settings.rooms.device_settings.form.custom_button.device_cancelation_events.on",
    ),
  },
  {
    value: 2,
    label: t(
      "desktop.settings.rooms.device_settings.form.custom_button.device_cancelation_events.only_spot",
    ),
  },
]

export const DEVICE_CHECKIN_PERIOD_OPTIONS: OptionType<number>[] = [
  0, 5, 15, 30,
].map((v) => ({
  value: v,
  label:
    v === 0
      ? t(
          "desktop.settings.rooms.device_settings.form.device_settings.time_duration_options.off",
        )
      : t(
          "desktop.settings.rooms.device_settings.form.device_settings.time_duration_options.number_of_minutes",
          { number: v },
        ),
}))

export const CUSTOM_BUTTON_STATES = {
  STATE_DISABLED: 0,
  STATE_ENABLED: 1,
  STATE_DELETED: 2,
}

export const AUTHENTICATION_TYPES = {
  NONE: 0,
  BASIC: 1,
}

export const AUTHENTICATION_TYPE_OPTIONS: OptionType<number>[] = [
  {
    value: AUTHENTICATION_TYPES.NONE,
    label: t(
      "desktop.settings.rooms.device_settings.form.custom_button.auth_types.none",
    ),
  },
  {
    value: AUTHENTICATION_TYPES.BASIC,
    label: t(
      "desktop.settings.rooms.device_settings.form.custom_button.auth_types.basic",
    ),
  },
]

export const ACTIONS_TYPES = {
  QRCODE: 1,
  WEBHOOK: 2,
  ZAPIER: 3,
}

export const CUSTOM_BUTTON_ACTION_TYPE_OPTIONS: OptionType<number>[] = [
  {
    value: ACTIONS_TYPES.QRCODE,
    label: t(
      "desktop.settings.rooms.device_settings.form.custom_button.action_types.qr_code",
    ),
  },
  {
    value: ACTIONS_TYPES.WEBHOOK,
    label: t(
      "desktop.settings.rooms.device_settings.form.custom_button.action_types.webhook",
    ),
  },
]

export const REPLACES = {
  MEET_NOW: 1,
  MEET_LATER: 2,
  TIMETABLE: 3,
}

export const REPLACEABLE_BUTTON_OPTIONS: OptionType<number>[] = [
  {
    value: REPLACES.MEET_NOW,
    label: t(
      "desktop.settings.rooms.device_settings.form.custom_button.replace_buttons.meet_now",
    ),
  },
  {
    value: REPLACES.MEET_LATER,
    label: t(
      "desktop.settings.rooms.device_settings.form.custom_button.replace_buttons.meet_later",
    ),
  },
  {
    value: REPLACES.TIMETABLE,
    label: t(
      "desktop.settings.rooms.device_settings.form.custom_button.replace_buttons.timetable",
    ),
  },
]

export const REPLACES_PRO = {
  NONE: 4,
  BOOK: 5,
  TIMETABLE: 3,
}

export const REPLACEABLE_DEVICE_6_PRO_BUTTON_OPTIONS: OptionType<number>[] = [
  {
    value: REPLACES_PRO.NONE,
    label: t(
      "desktop.settings.rooms.device_settings.form.custom_button.replace_device_6_pro_buttons.none",
    ),
  },
  {
    value: REPLACES_PRO.TIMETABLE,
    label: t(
      "desktop.settings.rooms.device_settings.form.custom_button.replace_device_6_pro_buttons.timetable",
    ),
  },
  {
    value: REPLACES_PRO.BOOK,
    label: t(
      "desktop.settings.rooms.device_settings.form.custom_button.replace_device_6_pro_buttons.book",
    ),
  },
]

export const SETTINGS_GROUPS = {
  GENERAL_GROUP: "GENERAL_GROUP",
  CUSTOM_LOGO_GROUP: "CUSTOM_LOGO_GROUP",
  OFFICE_HOURS_GROUP: "OFFICE_HOURS_GROUP",
  CUSTOM_BUTTON_GROUP: "CUSTOM_BUTTON_GROUP",
  LOCALIZATION_GROUP: "LOCALIZATION_GROUP",
  CUSTOM_CONTENT_GROUP: "CUSTOM_CONTENT_GROUP",
  MAINTENANCE_GROUP: "MAINTENANCE_GROUP",
} as const

export type SettingsGroups = ObjectToUnion<typeof SETTINGS_GROUPS>

const MINUTES_INTERVAL = 30

const OFFICE_HOURS: string[] = Array.from({ length: 48 }, (_, i) =>
  dayjs()
    .startOf("day")
    .add(i * MINUTES_INTERVAL, "minute")
    .format(internalTimeFormat()),
)

export const OFFICE_HOURS_OPTIONS = OFFICE_HOURS.map((h) => ({
  label: h,
  value: h,
}))

export const EFFECTIVE_SETTINGS_LOCATIONS = {
  COMPANY: "company",
  BUILDING: "building",
} as const
